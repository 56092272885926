/*
 src/reducers/simpleReducer.js
*/

export default (state = { login: false }, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'REGISTER_SUCCESS':
      console.log('=== auth SUCCESS reducer ===');
      return {
        user: action.payload,
        errMsg: '',
        login: true,
      };
    case 'LOGIN_ERROR':
    case 'REGISTER_ERROR':
      console.log('=== auth ERROR reducer ===');
      return {
        user: '',
        errMsg: action.payload,
        login: false,
      };
    case 'LOGOUT':
      console.log('=== LOGOUT reducer ===');
      return {
        user: '',
        errMsg: '',
        login: false,
      };
    default:
      return state;
  }
};
