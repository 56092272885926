/* eslint-disable react/jsx-key */
import React, { lazy } from 'react';
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { Route } from 'react-router-dom';

const SignIn = lazy(() => import('../pages/SignIn/SignIn'));
const SignUp = lazy(() => import('../pages/SignUp/SignUp'));
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'));
const Home = lazy(() => import('../pages/Home/Home'));
const Subscribe = lazy(() => import('../pages/Subscribe/Subscribe'));
const Profile = lazy(() => import('../pages/Profile/Profile'));
const Digital = lazy(() => import('../pages/Success/Digital'));
const Unlimited = lazy(() => import('../pages/Success/Unlimited'));
const Night = lazy(() => import('../pages/Success/Night'));
const Booking = lazy(() => import('../pages/Booking/Booking'));
const DetailProfile = lazy(() => import('../pages/MemberCenterProfile/DetailProfile'));
const EditDetailProfile = lazy(() => import('../pages/MemberCenterProfile/EditDetailProfile'));
const AccountSettings = lazy(() => import('../pages/AccountSettings/AccountSettings'));
const MyPlan = lazy(() => import('../pages/MyPlan/MyPlan'));
const MyBooking = lazy(() => import('../pages/MyBooking/MyBooking'));
const MyPayment = lazy(() => import('../pages/MyPayment/MyPayment'));
const MeetingRoom = lazy(() => import('../pages/MeetingRoom/MeetingRoom'));
const Venue = lazy(() => import('../pages/Venue/Venue'));


const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  <UnauthorizedRoute path="/signup" redirectTo="/" exact component={SignUp} />,
  <UnauthorizedRoute
    path="/password_reset"
    redirectTo="/"
    exact
    component={PasswordReset}
  />,

  <Route path="/venue" exact component={Venue} />,
  <Route path="/meeting_room" exact component={MeetingRoom} />,

  <AuthorizedRoute path="/home" exact component={Home} />,
  <AuthorizedRoute path="/my_payment" exact component={MyPayment} />,
  <AuthorizedRoute path="/my_booking" exact component={MyBooking} />,
  <AuthorizedRoute path="/my_plan" exact component={MyPlan} />,
  <AuthorizedRoute path="/account_settings" exact component={AccountSettings} />,
  <AuthorizedRoute path="/detail_profile" exact component={DetailProfile} />,
  <AuthorizedRoute path="/edit_detail_profile" exact component={EditDetailProfile} />,
  <AuthorizedRoute path="/subscribe" exact component={Subscribe} />,
  <AuthorizedRoute path="/profile" exact component={Profile} />,
  <AuthorizedRoute path="/digital" exact component={Digital} />,
  <AuthorizedRoute path="/unlimited" exact component={Unlimited} />,
  <AuthorizedRoute path="/night" exact component={Night} />,
  <AuthorizedRoute path="/booking" exact component={Booking} />,
];

export default routes;
