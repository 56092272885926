/*
 * src/store.js
 * With initialState
//  */
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducers/rootReducer';
//
// export default function configureStore(initialState = {}) {
//   return createStore(rootReducer, initialState, applyMiddleware(thunk));
// }

import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/rootReducer';

const persistConfig = {
  key: 'root', // 對於資料 key 的定義
  storage, // 選擇的儲存引擎
};

// 對 reducers 的封裝處理
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  const enhancers = compose(
    applyMiddleware(thunk),
  );

  // 處理後的reducers  需要作為引數傳遞在createStore中
  const store = createStore(persistedReducer, enhancers);

  //   持久化store
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
