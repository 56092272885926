export default (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_PROFILE':
      console.log('=== CLEAR_PROFILE reducer ===');
      return {
        userProfile: '',
      };
    case 'UPDATE_PROFILE':
      console.log('=== UPDATE_PROFILE reducer ===');
      return {
        userProfile: {
          ...action.payload,
        },
      };
    case 'UPDATE_ERROR':
      console.log('=== UPDATE_ERROR reducer ===');
      return {
        userProfile: {
          ...action.payload,
        },
      };
    case 'GET_PROFILE':
      console.log('=== GET_PROFILE reducer ===');
      return {
        userProfile: {
          ...action.payload,
        },
      };
    case 'GET_ERROR':
      console.log('=== GET_ERROR reducer ===');
      return {
        userProfile: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
