import React, { Component } from 'react';
import App from 'base-shell/lib';
import MUIConfig from 'material-ui-shell/lib';
import merge from 'base-shell/lib/utils/config';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import _config from './config';
import { simpleAction } from './actions/simpleAction';

const config = merge(MUIConfig, _config);

class Demo extends Component {
  simpleAction = (event) => {
    this.props.simpleAction();
  };

  render() {
    console.log('=== Demo ===', this.props);
    return (
      <App config={config}>
        <CssBaseline />
      </App>
    );
  }
}

//  <pre>{JSON.stringify(this.props)}</pre>
// <button onClick={this.simpleAction}>Test redux action</button>

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  simpleAction: () => dispatch(simpleAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Demo);
