import { lazy } from 'react';
// import parseLanguages from 'base-shell/lib/utils/locale';
import locales from './locales';
import routes from './routes';
import getMenuItems from './menuItems';
import themes from './themes';

const config = {
  auth: {
    signInURL: '/signin',
  },
  routes,
  locale: {
    locales,
    onError: (e) => {
      console.warn(e);
      return e;
    },
  },
  menu: {
    getMenuItems,
  },
  theme: {
    themes,
    defaultThemeID: 'blue',
    defaultType: 'light',
  },
  pages: {
    LandingPage: lazy(() => import('../pages/LandingPage/LandingPage')),
    PageNotFound: lazy(() => import('../pages/PageNotFound/PageNotFound')),
  },
};

export default config;
