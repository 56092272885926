import React from 'react';
import { render } from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import returnStoreAndPersistor from './store';
import App from './App';

const { store, persistor } = returnStoreAndPersistor();
const theme = createMuiTheme({
  palette: {
    palette: {
      primary: {
        light: '#6dfaff',
        main: '#26c7cd',
        dark: '#00969c',
        contrastText: '#fff',
      },
      secondary: {
        light: '#6dfaff',
        main: '#26c7cd',
        dark: '#fff',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        'Roboto',
        '-apple-system',
      ].join(','),
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          color: '#26c7cd',
          backgroundColor: '#fff',
        },
      },
    },
  },
  text: {
    fontFamily: 'Roboto',
  },
});
render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
