import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import green from '@material-ui/core/colors/green';

const themes = [
  {
    id: 'blue',
    color: '#26c7cd',
    source: {
      palette: {
        primary: {
          light: '#6dfaff',
          main: '#26c7cd',
          dark: '#00969c',
          contrastText: '#232323',
        },
        secondary: {
          light: '#424a67',
          main: '#19233c',
          dark: '#000017',
          contrastText: '#fff',
        },
      },
      typography: {
        fontFamily: [
          'Roboto',
          '-apple-system',
        ].join(','),
      },
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            color: '#19233c',
            backgroundColor: '#fff',
          },
        },
      },
    },
  },
  {
    id: 'red',
    color: red[500],
    source: {
      palette: {
        primary: red,
        secondary: pink,
        error: red,
      },
    },
  },
  {
    id: 'green',
    color: green[500],
    source: {
      palette: {
        primary: green,
        secondary: red,
        error: red,
      },
    },
  },
];

export default themes;
