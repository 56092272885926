/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
import authReducer from './authReducer';
import profileReducer from './profileReducer';

export default combineReducers({
  simpleReducer,
  authReducer,
  profileReducer,
});
