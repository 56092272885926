import React from 'react';
import DaschboardIcon from '@material-ui/icons/Dashboard';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import GetApp from '@material-ui/icons/GetApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import StyleIcon from '@material-ui/icons/Style';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import allThemes from './themes';

const getMenuItems = (props) => {
  const {
    intl,
    menuContext,
    themeContext,
    a2HSContext,
    auth: authData,
  } = props;
  const {
    isAuthMenuOpen,
  } = menuContext;
  const { themeID, setThemeID } = themeContext;
  const { auth, setAuth } = authData;
  const { isAppInstallable, isAppInstalled, deferredPrompt } = a2HSContext;

  const isAuthorised = auth.isAuthenticated;

  const themeItems = allThemes.map((t) => ({
    value: undefined,
    visible: true,
    primaryText: intl.formatMessage({ id: t.id }),
    onClick: () => {
      setThemeID(t.id);
    },
    leftIcon: <StyleIcon style={{ color: t.color }} />,
  }));

  if (isAuthMenuOpen || !isAuthorised) {
    return [
      {
        value: '/signin',
        onClick: isAuthorised
          ? () => {
            setAuth({ isAuthenticated: false });
          }
          : () => {},
        visible: true,
        primaryText: isAuthorised
          ? intl.formatMessage({ id: 'sign_out' })
          : intl.formatMessage({ id: 'sign_in' }),
        leftIcon: isAuthorised ? <ExitToAppIcon /> : <LockIcon />,
      },
    ];
  }
  return [
    {
      value: '/home',
      visible: isAuthorised,
      primaryText: '首頁',
      leftIcon: <DaschboardIcon />,
    },
    {
      value: '/venue',
      visible: true,
      primaryText: '活動場地',
      leftIcon: <LocalPlayIcon />,
    },
    {
      value: '/meeting_room',
      visible: true,
      primaryText: '會議室',
      leftIcon: <MeetingRoomIcon />,
    },
    { divider: true },
    {
      primaryText: '設定',
      primaryTogglesNestedList: true,
      leftIcon: <SettingsIcon />,
      nestedItems: [
        {
          primaryText: '佈景',
          secondaryText: intl.formatMessage({ id: themeID }),
          primaryTogglesNestedList: true,
          leftIcon: <StyleIcon />,
          nestedItems: themeItems,
        },
        {
          value: '/detail_profile',
          primaryText: '個人設定',
          visible: isAuthorised,
          leftIcon: <AccountBoxIcon />,
        },
        {
          value: '/account_settings',
          primaryText: '帳號設定',
          visible: isAuthorised,
          leftIcon: <SettingsApplicationsIcon />,
        },
        {
          value: '/my_plan',
          primaryText: '方案資訊',
          visible: isAuthorised,
          leftIcon: <AssignmentIcon />,
        },
        {
          value: '/my_booking',
          primaryText: '預約紀錄',
          visible: isAuthorised,
          leftIcon: <EventAvailableIcon />,
        },
        {
          value: '/my_payment',
          primaryText: '付款紀錄',
          visible: isAuthorised,
          leftIcon: <CreditCardIcon />,
        },
      ],
    },
    {
      value: '/about',
      visible: true,
      primaryText: '關於',
      leftIcon: <InfoOutlined />,
    },
    {
      value: null,
      visible: isAppInstallable && !isAppInstalled,
      onClick: () => {
        deferredPrompt.prompt();
      },
      primaryText: intl.formatMessage({
        id: 'install',
        defaultMessage: 'Install',
      }),
      leftIcon: <GetApp />,
    },
  ];
};
export default getMenuItems;
